var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-sheet",
            { staticClass: "pa-5", attrs: { color: "white" } },
            [
              _c("h3", { staticClass: "mb-5 text-center" }, [
                _vm._v("Transaction status"),
              ]),
              _vm.txLoading
                ? _c(
                    "div",
                    { staticClass: "pa-5" },
                    [
                      _c("v-progress-linear", {
                        attrs: { indeterminate: "", rounded: "", height: "6" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.txLoading
                ? _c(
                    "v-alert",
                    { attrs: { type: _vm.status ? "success" : "error" } },
                    [_vm._v(_vm._s(_vm.status ? "Success" : "Failed"))]
                  )
                : _vm._e(),
              _vm.txHash
                ? _c("div", { staticClass: "greyLight pa-5 mb-2" }, [
                    _c("div", { staticClass: "font-weight-bold" }, [
                      _vm._v("Transaction Hash"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("mew-transform-hash", {
                          attrs: { hash: _vm.txHash },
                        }),
                        _c("mew-copy", {
                          staticClass: "ml-2",
                          attrs: {
                            "copy-value": _vm.txHash,
                            tooltip: "Copy Me!",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "pa-5" },
                [
                  _vm.dialogAlert
                    ? _c("mew-alert", {
                        attrs: {
                          "hide-close-icon": "",
                          theme: "error",
                          title: "Error",
                          description: _vm.dialogAlert,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("mew-button", {
                    staticClass: "mt-6",
                    attrs: { title: "Close", "btn-size": "xlarge" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.dismiss.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("mew-module", {
        staticClass: "pt-6",
        attrs: {
          title: _vm.title.title,
          "has-elevation": true,
          "has-indicator": true,
        },
        scopedSlots: _vm._u([
          {
            key: "moduleBody",
            fn: function () {
              return [
                _c("mew-stepper", {
                  attrs: {
                    compact: _vm.$vuetify.breakpoint.smAndDown,
                    items: _vm.stepperItems,
                    "on-step": _vm.currentStep,
                  },
                }),
                _vm.currentStep === 1
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "mx-auto py-10",
                            attrs: {
                              color: "transparent",
                              "max-width": "600px",
                            },
                          },
                          [_c("network-switch")],
                          1
                        ),
                        _c("mew-button", {
                          staticClass: "mx-auto display--block NextButton",
                          attrs: { "btn-size": "xlarge", title: "Next" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.currentStep = 2
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentStep === 2
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "mx-auto py-10",
                            attrs: {
                              color: "transparent",
                              "max-width": "600px",
                            },
                          },
                          [
                            _c("mew-address-select", {
                              staticClass: "OfflineAddressInput",
                              attrs: {
                                label: "From Address",
                                "copy-tooltip": "Copy",
                                "save-tooltip": "Save Address",
                                "show-copy": _vm.detailLength,
                                "show-save": false,
                                items: _vm.addresses,
                                "enable-save-address": false,
                                "is-valid-address": _vm.detailLength,
                                "address-value": _vm.fromAddress,
                                "error-messages": _vm.fromAddressMessage,
                              },
                              on: { input: _vm.setAddress },
                            }),
                            _vm.detailLength
                              ? _c("mew-expand-panel", {
                                  staticClass:
                                    "mt-4 mb-10 swap-expend OfflineDetails",
                                  attrs: {
                                    "is-toggle": "",
                                    "has-dividers": "",
                                    "panel-items": _vm.exPanelStep2,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "panelBody1",
                                        fn: function () {
                                          return _vm._l(
                                            _vm.details,
                                            function (d, key) {
                                              return _c(
                                                "div",
                                                {
                                                  key: key,
                                                  staticClass:
                                                    "d-flex align-center justify-space-between mb-3 px-5",
                                                },
                                                [
                                                  d.title
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pr-3" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(d.title)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  d.value
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(d.value)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  d.address
                                                    ? _c("mew-transform-hash", {
                                                        attrs: {
                                                          hash: d.address,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2233926829
                                  ),
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-block d-lg-flex justify-center mt-2 text-center",
                              },
                              [
                                _c("mew-button", {
                                  staticClass: "mx-1 mb-3",
                                  attrs: {
                                    title: "Back",
                                    "btn-size": "xlarge",
                                    "btn-style": "outline",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleBack.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                                _c("mew-button", {
                                  staticClass: "mx-1 mb-3 NextButton2",
                                  attrs: {
                                    title: "Next",
                                    "btn-size": "xlarge",
                                    disabled: !_vm.detailLength,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.currentStep = 3
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.detailLength
                              ? _c("mew-button", {
                                  staticClass:
                                    "mt-2 d-flex align-center justify-center mx-auto DownloadButton",
                                  staticStyle: { "max-width": "130px" },
                                  attrs: {
                                    title: "Export JSON file",
                                    "btn-size": "small",
                                    "btn-style": "transparent",
                                    "btn-link": _vm.fileLink,
                                    download: _vm.exportFileName,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.currentStep === 3
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-sheet",
                          {
                            staticClass: "mx-auto py-10",
                            attrs: {
                              color: "transparent",
                              "max-width": "600px",
                            },
                          },
                          [
                            _c("v-textarea", {
                              staticClass: "mb-1 SignatureInput",
                              attrs: {
                                outlined: "",
                                label: "Signature",
                                value: _vm.signature,
                                error: _vm.signatureError,
                                "error-messages": _vm.signatureMessage,
                              },
                              on: { input: _vm.checkTx },
                            }),
                            _vm._l(_vm.sortedAlerts, function (alert) {
                              return _c("mew-alert", {
                                key: alert.key,
                                staticClass: "mb-3",
                                attrs: {
                                  "hide-close-icon": "",
                                  theme: alert.severity,
                                  title: alert.title,
                                  description: alert.message,
                                },
                              })
                            }),
                            !_vm.signatureError && _vm.signature !== ""
                              ? _c("mew-expand-panel", {
                                  staticClass:
                                    "mt-4 mb-10 swap-expend SignatureRawDetails",
                                  attrs: {
                                    "is-toggle": "",
                                    "has-dividers": "",
                                    "panel-items": _vm.exPanelStep3,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "panelBody1",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center justify-space-between mb-3 px-5",
                                              },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    readonly: "",
                                                    "auto-grow": "",
                                                    value: _vm.rawTransaction,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "panelBody2",
                                        fn: function () {
                                          return _vm._l(
                                            _vm.transactionDetails,
                                            function (d, key) {
                                              return _c(
                                                "div",
                                                {
                                                  key: key,
                                                  staticClass:
                                                    "d-flex align-center justify-space-between mb-3 pa-5",
                                                },
                                                [
                                                  d.title
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pr-3" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(d.title)
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  d.value
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          staticStyle: {
                                                            "overflow-x":
                                                              "hidden",
                                                            "text-overflow":
                                                              "ellipsis",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(d.value) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  d.address
                                                    ? _c("mew-transform-hash", {
                                                        attrs: {
                                                          hash: d.address,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    309422960
                                  ),
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-block d-lg-flex justify-center mt-2 text-center",
                              },
                              [
                                _c("mew-button", {
                                  staticClass: "mx-1 mb-3",
                                  attrs: {
                                    title: "Back",
                                    "btn-size": "xlarge",
                                    "btn-style": "outline",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleBack.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                                _c("mew-button", {
                                  staticClass: "mx-1 mb-3 ConfirmSendButton",
                                  attrs: {
                                    title: "Confirm & Send",
                                    "btn-size": "xlarge",
                                    disabled: !_vm.validTx || _vm.error,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.sendTx.apply(null, arguments)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c("input", {
                                  ref: "uploadSig",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", accept: "json" },
                                  on: { change: _vm.uploadFile },
                                }),
                                _c("mew-button", {
                                  staticClass: "mt-2 display--block mx-auto",
                                  attrs: {
                                    title: "Upload JSON file",
                                    "btn-size": "small",
                                    "btn-style": "transparent",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.$refs.uploadSig.click()
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }